"use client";

import { useEffect, useState } from "react";
import {
  BrowserSearchIcon,
  CashIcon,
  ChatDotsIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  CreditCardIcon,
  FontIcon,
  InvoiceIcon,
  LocationIcon,
  LogOutIcon,
  QuestionCircleIcon,
  TelephoneIcon,
  TiendanubeIcon,
  WhatsappIcon,
} from "@nimbus-ds/icons";
import { signOut } from "next-auth/react";
import { useLocale, useTranslations } from "next-intl";

import Badge from "~/components/Badge";
import { ConfirmModal, Divider, MenuButton, Popover, Tag } from "~/components";
import {
  CUSTOMER_SERVICE_PHONE_CALL_URL_BY_COUNTRY,
  CUSTOMER_SERVICE_WHATSAPP_BY_COUNTRY,
} from "~/constants";
import { clearUserSessionData } from "~/helpers";
import { trackAmplitudeEvent } from "~/helpers/analytics";
import { getLocaleFieldValue } from "~/helpers/i18n";
import openNewTab from "~/helpers/openNewTab";
import {
  useUISmallScreenCartMode,
  useUISmallScreenDrawerVisible,
  useUIStore,
} from "~/hooks";
import useHelpUrl from "~/hooks/useHelpUrl";
import useIsSmallScreen from "~/hooks/useIsSmallScreen";
import useSelectedLocation from "~/hooks/useSelectedLocation";
import { useRouter } from "~/navigation";
import { useStoreInfo } from "~/providers/StoreInfoProvider";
import { useZendeskWebWidget } from "~/providers/ZendeskWebWidgetProvider";
import ShortcutListModalWidget from "~/widgets/ShortcutListModalWidget";
import SelectLocationModalWidget from "../SelectLocationModalWidget";
import UserNameInitials from "./UserNameInitials";

export default function AppMenuWidget() {
  const t = useTranslations("widgets.app-menu");
  const router = useRouter();
  const locale = useLocale();
  const storeInfo = useStoreInfo();
  const isSmallScreen = useIsSmallScreen();

  const { name, country, adminLanguage, userConfig, email, features } =
    storeInfo;
  const storeName = getLocaleFieldValue(name, locale);
  const countryLocale = `${adminLanguage}_${country}`;
  const firstName = userConfig?.firstName ?? undefined;
  const lastName = userConfig?.lastName ?? undefined;

  const smallScreenCartMode = useUISmallScreenCartMode();
  const smallScreenDrawerVisible = useUISmallScreenDrawerVisible();
  const { hideSmallScreenDrawer } = useUIStore();

  const { hasMultiCD, originLocation } = useSelectedLocation();

  const selectedOriginLocationName = getLocaleFieldValue(
    originLocation.name,
    countryLocale,
  );

  const zendesk = useZendeskWebWidget();
  const helpUrl = useHelpUrl();

  const [menuOpen, setMenuOpen] = useState(false);
  const [helpPopoverVisible, setHelpPopoverVisible] = useState(false);
  const [shortcutListOpen, setShortcutListOpen] = useState(false);
  const [signOutConfirmModalOpen, setSignOutConfirmModalOpen] = useState(false);
  const [selectLocationModalOpen, setSelectLocationModalOpen] = useState(false);

  useEffect(() => {
    if (menuOpen || smallScreenDrawerVisible) {
      router.prefetch("/account");
      router.prefetch("/account/plans");
    }
  }, [smallScreenDrawerVisible, menuOpen, router]);

  const handleVisibilityChange = (visible: boolean) => {
    setMenuOpen(visible);

    if (visible) {
      trackAmplitudeEvent("pdv_headerbar_profile_click");
    }
  };

  const handleMyAccountClick = () => {
    router.push("/account");
    hideSmallScreenDrawer();
    trackAmplitudeEvent("pdv_profile_menu_payments_click");
  };

  const handlePlansClick = () => {
    router.push("/account/plans");
    hideSmallScreenDrawer();
    trackAmplitudeEvent("pdv_profile_menu_plans_click");
  };

  const handleChangeLocationClick = () => {
    setSelectLocationModalOpen(true);
    setMenuOpen(false);
    trackAmplitudeEvent("pdv_profile_menu_location_click");
    hideSmallScreenDrawer();
  };

  const handlePaymentMethodsClick = () => {
    router.push("/settings/payment-methods");
    setMenuOpen(false);
    trackAmplitudeEvent("pdv_settings_navbar_payments_click");
    hideSmallScreenDrawer();
  };

  const handleSignOutClick = () => {
    setMenuOpen(false);
    setSignOutConfirmModalOpen(true);
    trackAmplitudeEvent("pdv_profile_menu_logout_click");
    hideSmallScreenDrawer();
  };

  const handleSignOutConfirm = () => {
    zendesk.logoutUser();
    clearUserSessionData();
    signOut();
  };

  const handleHelpPopoverVisibility = (visible: boolean) => {
    setHelpPopoverVisible(visible);
    trackAmplitudeEvent("pdv_headerbar_help_click");
  };

  const handleKeyboardShortcutsClick = () => {
    setHelpPopoverVisible(false);
    setShortcutListOpen(true);
    trackAmplitudeEvent("pdv_headerbar_help_click", {
      help_option: "shortcuts_map",
    });
  };

  const handleCallCenterClick = () => {
    setHelpPopoverVisible(false);
    openNewTab(helpUrl, t("popUpBlockedMessage"));
    trackAmplitudeEvent("pdv_headerbar_help_click", {
      help_option: "can",
    });
  };

  const handleContactUsClick = () => {
    setHelpPopoverVisible(false);
    zendesk.open();
    trackAmplitudeEvent("pdv_headerbar_help_click", {
      help_option: "chat",
    });
  };

  const handleWhatsappClick = () => {
    setHelpPopoverVisible(false);
    const url =
      CUSTOMER_SERVICE_WHATSAPP_BY_COUNTRY[country] ??
      CUSTOMER_SERVICE_WHATSAPP_BY_COUNTRY.default;
    openNewTab(url, t("popUpBlockedMessage"));
    trackAmplitudeEvent("pdv_headerbar_help_click", {
      help_option: "whatsapp",
    });
  };

  const handleCallClick = () => {
    setHelpPopoverVisible(false);

    if (country !== "BR") {
      return;
    }

    const url = CUSTOMER_SERVICE_PHONE_CALL_URL_BY_COUNTRY[country];
    openNewTab(url, t("popUpBlockedMessage"));
    trackAmplitudeEvent("pdv_headerbar_help_click", {
      help_option: "phone",
    });
  };

  const isLauncherMode = smallScreenCartMode === "launcher";
  const showAppMenu = !isSmallScreen || isLauncherMode;

  return (
    <>
      {isSmallScreen && smallScreenDrawerVisible && (
        <div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col gap-4 bg-white p-4">
          <button
            onClick={hideSmallScreenDrawer}
            className="absolute right-4 top-4"
          >
            <CloseIcon size="medium" className="cursor-pointer" />
          </button>

          <h1 className="text-2xl font-bold">{storeName}</h1>

          <div className="flex items-center gap-2">
            <UserNameInitials firstName={firstName} lastName={lastName} />

            <div className="flex flex-col">
              <div className=" font-medium">
                {firstName} {lastName}
              </div>
              <div className="text-sm">{email}</div>
            </div>
          </div>

          {selectedOriginLocationName && (
            <Tag appearance="success">
              <div className="flex items-center gap-1">
                <LocationIcon />
                {selectedOriginLocationName}
              </div>
            </Tag>
          )}

          <div className="flex flex-col gap-2 pt-2">
            <Divider />
            <MenuButton
              label={t("accountPopoverItems.paymentsAndSubscriptions")}
              startIcon={() => <CashIcon size={18} />}
              onClick={handleMyAccountClick}
              align="right"
            />

            <Divider />
            <MenuButton
              label={t("accountPopoverItems.plans")}
              startIcon={() => <InvoiceIcon size={18} />}
              onClick={handlePlansClick}
            />

            {hasMultiCD && (
              <>
                <Divider />
                <MenuButton
                  label={t("accountPopoverItems.changeLocation")}
                  startIcon={() => <LocationIcon size={18} />}
                  onClick={handleChangeLocationClick}
                />
              </>
            )}

            <Divider />
            <MenuButton
              label={t("accountPopoverItems.paymentMethods")}
              startIcon={() => <CreditCardIcon size={18} />}
              onClick={handlePaymentMethodsClick}
            />

            <Divider />
            <MenuButton
              label={t("accountPopoverItems.signOut")}
              startIcon={() => <LogOutIcon size={18} />}
              onClick={handleSignOutClick}
            />

            <Divider />
          </div>
        </div>
      )}

      {showAppMenu && (
        <div className="flex w-full items-center justify-end gap-3 border-b border-neutral-surface-highlight bg-neutral-background px-4 py-4 sm:px-6 sm:py-2">
          {isSmallScreen && (
            <div className="flex w-full items-center gap-3">
              <TiendanubeIcon size={30} />
              <h1 className="flex-1 text-xl font-medium text-primary-text-high">
                {storeName}
              </h1>
            </div>
          )}

          <Popover
            visible={helpPopoverVisible}
            onVisibility={handleHelpPopoverVisibility}
            arrow={false}
            padding="small"
            position="bottom-end"
            title={t("helpPopoverTitle")}
            width="288px"
            content={
              <div className="flex w-full flex-col gap-2">
                <div className="p-2 font-semibold text-neutral-text-high">
                  {t("helpPopoverTitle")}
                </div>

                {!isSmallScreen && (
                  <>
                    <MenuButton
                      label={t("helpPopoverItems.keyboardShortcuts")}
                      startIcon={() => <FontIcon size={18} />}
                      onClick={handleKeyboardShortcutsClick}
                    />

                    <Divider />
                  </>
                )}

                <MenuButton
                  label={t("helpPopoverItems.callCenter")}
                  startIcon={() => <BrowserSearchIcon size={18} />}
                  onClick={handleCallCenterClick}
                />

                {zendesk.isReady && (
                  <MenuButton
                    label={t("helpPopoverItems.contactUs")}
                    startIcon={() => <ChatDotsIcon size={18} />}
                    onClick={handleContactUsClick}
                  >
                    {zendesk.unreadMessages > 0 && (
                      <Badge
                        appearance="primary"
                        count={zendesk.unreadMessages}
                      />
                    )}
                  </MenuButton>
                )}

                {features.customerServiceWhatsapp && (
                  <MenuButton
                    label={t("helpPopoverItems.whatsapp")}
                    startIcon={() => <WhatsappIcon size={18} />}
                    onClick={handleWhatsappClick}
                  />
                )}

                {features.customerServicePhoneCall && country === "BR" && (
                  <MenuButton
                    label={t("helpPopoverItems.call")}
                    startIcon={() => <TelephoneIcon size={18} />}
                    onClick={handleCallClick}
                  />
                )}
              </div>
            }
          >
            <div className="relative">
              {zendesk.unreadMessages > 0 && (
                <div className="absolute -right-1 -top-1 h-1.5 w-1.5 rounded-full bg-primary-interactive"></div>
              )}
              <QuestionCircleIcon width={24} height={24} cursor="pointer" />
            </div>
          </Popover>

          {!isSmallScreen && (
            <div className="flex flex-row items-center gap-2 p-2">
              <UserNameInitials firstName={firstName} lastName={lastName} />

              <Popover
                visible={menuOpen}
                onVisibility={(visible) => handleVisibilityChange(visible)}
                arrow={false}
                padding="small"
                width="250px"
                position="bottom-end"
                content={
                  <div className="flex w-full flex-col gap-2">
                    <div className="p-2 font-semibold text-neutral-text-high">
                      {t("accountPopoverTitle")}
                    </div>

                    <MenuButton
                      label={t("accountPopoverItems.paymentsAndSubscriptions")}
                      onClick={handleMyAccountClick}
                    />
                    <MenuButton
                      label={t("accountPopoverItems.plans")}
                      onClick={handlePlansClick}
                    />

                    {hasMultiCD && (
                      <MenuButton
                        label={t("accountPopoverItems.changeLocation")}
                        onClick={handleChangeLocationClick}
                      />
                    )}

                    <MenuButton
                      label={t("accountPopoverItems.signOut")}
                      startIcon={() => <LogOutIcon size={18} />}
                      onClick={handleSignOutClick}
                    />
                  </div>
                }
              >
                <div className="flex cursor-pointer flex-row items-center gap-2 text-primary-text-low ">
                  <div className="flex flex-col items-start justify-center gap-1">
                    <div className="text-sm font-medium">
                      {hasMultiCD ? selectedOriginLocationName : storeName}
                    </div>
                  </div>
                  {menuOpen ? (
                    <ChevronUpIcon width={20} height={20} />
                  ) : (
                    <ChevronDownIcon width={20} height={20} />
                  )}
                </div>
              </Popover>
            </div>
          )}
        </div>
      )}

      <ConfirmModal
        open={signOutConfirmModalOpen}
        onDismiss={() => setSignOutConfirmModalOpen(false)}
        onConfirm={handleSignOutConfirm}
        title={t("signOutConfirmModal.title")}
        description={t("signOutConfirmModal.description")}
        dismissButtonText={t("signOutConfirmModal.dismissButtonText")}
        confirmButtonText={t("signOutConfirmModal.confirmButtonText")}
      />

      <ShortcutListModalWidget
        open={shortcutListOpen}
        onAfterModalLeave={() => setShortcutListOpen(false)}
      />

      {selectLocationModalOpen && (
        <SelectLocationModalWidget
          onAfterModalLeave={() => setSelectLocationModalOpen(false)}
        />
      )}
    </>
  );
}
